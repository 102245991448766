import React from 'react'
import HelmetMaterialUIIcons from './helpers/HelmetMaterialUIIcons'
import Router from './Router'
import { HelmetProvider } from 'react-helmet-async'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AuthProvider } from 'src/components/AuthProvider'

const App: React.FC = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <AuthProvider>
      <HelmetProvider>
        <HelmetMaterialUIIcons />
        <Router />
      </HelmetProvider>
    </AuthProvider>
  </LocalizationProvider>
)

export default App

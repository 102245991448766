import { useQuery } from 'react-query'
import { COMPANIES, COMPANY, EMPLOYEE, EMPLOYEES, OVERVIEW, USER, USERS } from '../consts/QueryKeys'
import {
  createCompany,
  createEmployee,
  getCompanies,
  getCompanyById,
  getEmployeeById,
  getEmployees,
  getOverview,
  getUsers,
  refreshToken,
  signin,
  signup,
  updateCompany,
  updateEmployee
} from './ApiHandler'
import usePatchMutation from 'src/hooks/usePatchMutation'
import useMutation from 'src/hooks/useMutation'
import { User } from 'src/components/AuthProvider'

export const useCompanies = (user: User, filter?: any) => useQuery([COMPANIES], () => getCompanies(user, filter))
export const useCreateCompany = (user: User) => useMutation([COMPANIES], (req) => createCompany(user, req))
export const useCompany = (user: User, companyId: string) =>
  useQuery([COMPANY, companyId], () => getCompanyById(user, companyId))
export const useUpdateCompany = (user: User, companyId: string) =>
  usePatchMutation([COMPANY, companyId], (req) => updateCompany(user, companyId, req), [])
export const useEmployees = (user: User, filter?: any) => useQuery([EMPLOYEES], () => getEmployees(user, filter))
export const useCreateEmployee = (user: User) => useMutation([EMPLOYEES], (req) => createEmployee(user, req))
export const useEmployee = (user: User, employeeId: string) =>
  useQuery([EMPLOYEE, employeeId], () => getEmployeeById(user, employeeId))
export const useUpdateEmployee = (user: User, employeeId: string) =>
  usePatchMutation([EMPLOYEE, employeeId], (req) => updateEmployee(user, employeeId, req), [])
export const useLogin = () => useMutation([USER], (req: any) => signin(req))
export const useRegister = () => useMutation([USER], (req) => signup(req))
export const useRefreshCredentials = (user: User) => useQuery([USER], (req) => refreshToken(user, req))
export const useOverview = (user: User, filter: any) => useQuery([OVERVIEW], () => getOverview(user, filter))
export const useUsers = (user: User, filter?: any) => useQuery([USERS], () => getUsers(user, filter))

import { memo } from 'react'
import PropagateLoader from 'react-spinners/PropagateLoader'
import styled from 'styled-components'
import FigmaColors from '../figma/tokens/FigmaColors'
import StyledBox from '../moesia/components/StyledBox'

const DevHarbourLoader = () => {
  return (
    <PositionedBox fullWidth>
      <PropagateLoader size={50} color={FigmaColors.bhBlue} />
    </PositionedBox>
  )
}

export default memo(DevHarbourLoader)

const PositionedBox = styled(StyledBox)`
  position: absolute;
  top: 45%;
  left: 47%;
`

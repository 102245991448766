import { memo } from 'react'
import { Images } from 'src/figma/helpers/imageHelper'
import { Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextFieldController } from 'src/moesia/components/ReactHookComponents'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import styled from 'styled-components'
import SVGContainer from 'src/moesia/components/SVGContainer'
import loginPageLogo from 'src/figma/images/loginPageLogo'
import { LOGIN_BUTTON_HEIGHT, LOGIN_PAGE_MAX_HEIGHT, LOGIN_PAGE_MAX_WIDTH } from 'src/moesia/StrongHardCodedSizes'
import { AuthButtons } from 'src/interfaces/AuthInterface'
import ExperimentalImageContainer from 'src/moesia/components/ExperimentalImageContainer'

const LoginPageDesktopView: React.FC<AuthButtons> = ({ control, handleLogin, handleAutoLogin }) => {
  return (
    <PageWrapper fullWidth fullPadding spacing={Spacings.large} align="center" justify="center">
      <LoginPage fullWidth direction="row" justify="space-between">
        <StyledBox fullPadding top={Spacings.large} spacing={Spacings.large}>
          <StyledBox direction="row" gap={Spacings.medium} align="center">
            <SVGContainer SVG={loginPageLogo} />
            <TextContainer textKey={Texts.LoginPageHeading} />
          </StyledBox>
          <StyledBox
            fullPadding
            top={Spacings.big}
            left={Spacings.huge}
            gap={Spacings.medium}
            align="center"
            justify="center"
          >
            <StyledBox align="center" alignText="center" gap={Spacings.tiny}>
              <TextContainer textKey={Texts.LoginFormHeading} />
              <TextContainer textKey={Texts.LoginFormSubheading} />
            </StyledBox>
            <TextFieldController
              containerStyles={{ width: '100%', borderRadius: `${BorderRadius.verySoft}` }}
              inputStyles={{ backgroundColor: FigmaColors.surfaceOnWhite }}
              control={control}
              name="email"
              labelTextKey={Texts.LoginEmailLabel}
              autoComplete="new-password"
            />
            <TextFieldController
              containerStyles={{ width: '100%', borderRadius: `${BorderRadius.verySoft}` }}
              inputStyles={{ backgroundColor: FigmaColors.surfaceOnWhite }}
              control={control}
              type="password"
              name="password"
              labelTextKey={Texts.LoginPasswordLabel}
              autoComplete="new-password"
            />
            <ForgotPassword alignSelf="flex-end" alignText="right">
              <TextContainer textKey={Texts.LoginForgotPassword} />
            </ForgotPassword>
            <StyledBox fullWidth gap={Spacings.medium} align="center" justify="center">
              <LoginButton onClick={handleLogin} fullWidth fullPadding spacing={Spacings.min} alignText="center">
                <TextContainer textKey={Texts.LoginButtonText} />
              </LoginButton>
              <AutoLoginButton
                onClick={handleAutoLogin}
                fullWidth
                fullPadding
                spacing={Spacings.min}
                alignText="center"
              >
                <TextContainer textKey={Texts.AutoLoginButtonText} />
              </AutoLoginButton>
            </StyledBox>
          </StyledBox>
        </StyledBox>
        <StyledBox>
          <ExperimentalImageContainer imageKey={Images.loginPageImage} />
        </StyledBox>
      </LoginPage>
    </PageWrapper>
  )
}

export default memo(LoginPageDesktopView)

const PageWrapper = styled(StyledBox)`
  height: 100vh;
  box-sizing: border-box;
  background-color: ${FigmaColors.lightStone};
  &&& {
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: ${BorderRadius.verySoft} !important;
    }
  }
`

const LoginPage = styled(StyledBox)`
  max-width: ${LOGIN_PAGE_MAX_WIDTH};
  max-height: ${LOGIN_PAGE_MAX_HEIGHT};
  border-radius: ${BorderRadius.verySoft};
  overflow: hidden;
  background-color: ${FigmaColors.white};
`

const LoginButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.loginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.blueDark};
  }
`

const AutoLoginButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.autoLoginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.bhBlue};
  }
`
const ForgotPassword = styled(StyledBox)`
  cursor: pointer;
  span:hover {
    color: ${FigmaColors.grayDark} !important;
  }
`

import React, { useMemo } from 'react'
import { generateUUID } from 'src/helpers/Common'
import { getSidebarContent, SidebarItemType } from 'src/helpers/SidebarHelper'
import SidebarButton from './SidebarButton'
import SidebarDivider from './SidebarDivider'

const SidebarNavigationItems: React.FC = () => {
  const sidebarItems = useMemo(() => getSidebarContent(), [])

  return (
    <>
      {!!sidebarItems &&
        sidebarItems.map(({ icon, textKey, type, url }) => {
          return (
            <React.Fragment key={generateUUID()}>
              {type === SidebarItemType.BUTTON && !!icon && !!url && (
                <SidebarButton icon={icon} textKey={textKey} url={url} />
              )}
              {type === SidebarItemType.DIVIDER && <SidebarDivider textKey={textKey} />}
            </React.Fragment>
          )
        })}
    </>
  )
}

export default SidebarNavigationItems

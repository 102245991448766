import { memo } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextFieldController } from 'src/moesia/components/ReactHookComponents'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import styled from 'styled-components'
import SVGContainer from 'src/moesia/components/SVGContainer'
import { LOGIN_BUTTON_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import loginPageLogoMobile from 'src/figma/images/loginPageLogoMobile'

const LoginPageMobileView = ({ control }: any) => {
  return (
    <LoginPage fullWidth align="center" justify="center">
      <StyledBox fullPadding top={Spacings.large} spacing={Spacings.large}>
        <StyledBox fullWidth gap={Spacings.tiny} align="center" justify="center">
          <SVGContainer SVG={loginPageLogoMobile} />
          <TextContainer textKey={Texts.LoginPageHeading} />
        </StyledBox>
        <StyledBox fullPadding spacing={Spacings.big} gap={Spacings.medium} align="center" justify="center">
          <StyledBox align="center" alignText="center" gap={Spacings.tiny}>
            <TextContainer textKey={Texts.LoginFormHeading} />
            <TextContainer textKey={Texts.LoginFormSubheading} />
          </StyledBox>
          <TextFieldController
            containerStyles={{ width: '100%', borderRadius: `${BorderRadius.verySoft}` }}
            inputStyles={{ backgroundColor: FigmaColors.surfaceOnWhite }}
            control={control}
            name="email"
            labelTextKey={Texts.LoginEmailLabel}
          />
          <TextFieldController
            containerStyles={{ width: '100%', borderRadius: `${BorderRadius.verySoft}` }}
            inputStyles={{ backgroundColor: FigmaColors.surfaceOnWhite }}
            control={control}
            type="password"
            name="password"
            labelTextKey={Texts.LoginPasswordLabel}
          />
          <ForgotPassword alignSelf="flex-end" alignText="right">
            <TextContainer textKey={Texts.LoginForgotPassword} />
          </ForgotPassword>
          <StyledBox fullWidth gap={Spacings.medium} align="center" justify="center">
            <LoginButton
              fullWidth
              fullPadding
              spacing={Spacings.min}
              alignText="center"
              align="center"
              justify="center"
            >
              <TextContainer textKey={Texts.LoginButtonText} />
            </LoginButton>
            <AutoLoginButton
              fullWidth
              fullPadding
              spacing={Spacings.min}
              alignText="center"
              align="center"
              justify="center"
            >
              <TextContainer textKey={Texts.AutoLoginButtonText} />
            </AutoLoginButton>
          </StyledBox>
        </StyledBox>
      </StyledBox>
    </LoginPage>
  )
}

export default memo(LoginPageMobileView)

const LoginPage = styled(StyledBox)`
  height: 100vh;
  background-color: ${FigmaColors.white};
  &&& {
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: ${BorderRadius.verySoft} !important;
    }
  }
`

const LoginButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.loginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.blueDark};
  }
`

const AutoLoginButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.autoLoginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.bhBlue};
  }
`
const ForgotPassword = styled(StyledBox)`
  cursor: pointer;
  span:hover {
    color: ${FigmaColors.grayDark} !important;
  }
`

import React, { useState } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import closeIcon from 'src/figma/images/closeIcon'
import navigationLogo from 'src/figma/images/navigationLogo'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import { MOBILE_HEADER_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import MobileAccountMenu from './MobileAccountMenu'
import SidebarNavigationItems from './SidebarNavigationItems'

type Props = {
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileMenu: React.FC<Props> = ({ isMenuOpen, setIsMenuOpen }) => {
  const [isOpenAccountMenu, setIsOpenAccountMenu] = useState(false)

  return (
    <Wrapper fullWidth>
      <NavigationWrapper fullWidth fullPadding direction="row" spacing={Spacings.min} justify="space-between">
        <StyledBox direction="row" gap={Spacings.tiny} align="center" justify="center">
          <StyledDevHarbourLogo />
          <TextContainer textKey={Texts.NavigationTitle} />
        </StyledBox>
        <StyledBox onClick={() => setIsMenuOpen(false)} right={Spacings.minimum} justify="center">
          <SVGContainer SVG={closeIcon} />
        </StyledBox>
      </NavigationWrapper>
      {!!isMenuOpen && !isOpenAccountMenu && (
        <StyledWrapper fullWidth justify="space-between">
          <StyledBox fullWidth fullPadding spacing={Spacings.medium}>
            <SidebarNavigationItems />
          </StyledBox>
          <MenuUserInformation iconType={UserInformationType.OPTIONS} onClick={() => setIsOpenAccountMenu(true)} />
        </StyledWrapper>
      )}

      {!!isOpenAccountMenu && <MobileAccountMenu setIsOpenAccountMenu={setIsOpenAccountMenu} />}
    </Wrapper>
  )
}

export default MobileMenu

const NavigationWrapper = styled(StyledBox)`
  height: ${MOBILE_HEADER_HEIGHT};
  z-index: 999;
  background-color: ${FigmaColors.white};
  box-shadow: ${Shadows.baseMedium};
`

const StyledDevHarbourLogo = styled(navigationLogo)`
  height: 100%;
  span {
    height: 100% !important;
  }
`

const StyledWrapper = styled(StyledBox)`
  height: calc(100vh - (${MOBILE_HEADER_HEIGHT}));
  background-color: ${FigmaColors.white};
`
const Wrapper = styled(StyledBox)`
  background-color: ${FigmaColors.white};
  * {
    box-sizing: border-box;
  }
`

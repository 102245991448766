import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import userMenuLogoutIcon from 'src/figma/images/userMenuLogoutIcon'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import { useAppState } from 'src/components/AuthProvider'
import { generateUUID } from 'src/helpers/Common'
import { getAccountMenuContent } from 'src/helpers/SidebarHelper'
import TokenService from 'src/helpers/TokenHelper'
import StyledBox from 'src/moesia/components/StyledBox'
import { SIDEBAR_ACCOUNT_MENU_WIDTH, SIDEBAR_ELEMENTS_BORDER } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import SidebarAccountMenuItem from './SidebarAccountMenuItem'
import SidebarLanguageMenu from './SidebarLanguageMenu'
import { useNavigate } from 'react-router-dom'

const SidebarAccountMenu: React.FC = () => {
  const { state, setState } = useAppState()
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false)
  const navigate = useNavigate()

  const userMenuItems = useMemo(() => getAccountMenuContent(), [])

  const handleClick = (idx: number, url?: string) => {
    return idx === userMenuItems.length - 1 ? () => setIsOpenLanguageMenu(true) : () => navigate(url || '')
  }

  const handleLogout = () => {
    setState({ ...state, user: null })
    TokenService.removeUser()
  }

  return (
    <>
      <AnimatePresence mode="wait">
        {!isOpenLanguageMenu && (
          <Wrapper
            key={generateUUID()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <MenuUserInformation iconType={UserInformationType.NONE} />
            <StyledBox fullWidth fullPadding spacing={Spacings.min} gap={Spacings.tiny}>
              <StyledBox fullWidth>
                {!!userMenuItems &&
                  userMenuItems.map(({ icon, iconMobile, textKey, arrow, url }, idx) => {
                    return (
                      <SidebarAccountMenuItem
                        onClick={handleClick(idx, url)}
                        key={generateUUID()}
                        icon={icon}
                        textKey={textKey}
                        arrow={arrow}
                        iconMobile={iconMobile}
                      />
                    )
                  })}
              </StyledBox>
              <StyledSidebarAccountMenuItem
                icon={userMenuLogoutIcon}
                textKey={Texts.UserMenuLogOutTitle}
                onClick={handleLogout}
                iconMobile={userMenuLogoutIcon}
              />
            </StyledBox>
          </Wrapper>
        )}
      </AnimatePresence>
      {!!isOpenLanguageMenu && <SidebarLanguageMenu setIsOpenLanguageMenu={setIsOpenLanguageMenu} />}
    </>
  )
}

export default SidebarAccountMenu

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: ${SIDEBAR_ACCOUNT_MENU_WIDTH};
  position: absolute;
  overflow: hidden;
  z-index: 999;
  border-radius: ${BorderRadius.soft};
  bottom: 30px; //TODO check for better solution bc this is not responsive
  left: 300px; //TODO check for better solution
  background-color: ${FigmaColors.white};
  box-shadow: ${Shadows.baseHeavy};
`

const StyledSidebarAccountMenuItem = styled(SidebarAccountMenuItem)`
  //TODO WHY its not working

  border-top: ${SIDEBAR_ELEMENTS_BORDER} !important;
  background-color: red !important;
`

import styled from '@emotion/styled'
import Cancel from '@mui/icons-material/Cancel'
import Event from '@mui/icons-material/Event'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import { FocusEventHandler, HTMLInputTypeAttribute, useCallback, useRef } from 'react'
import { Control, Controller, Path } from 'react-hook-form'
import useLanguage from '../../hooks/useLanguage'
import MaterialSwitchExperimental from './MaterialSwitchExperimental'
import StyledBox from './StyledBox'
import { TextType } from '../interfaces/FigmaTypes'
import { getText } from '../../figma/helpers/TextRepository'
import { css, CSSObject } from '@emotion/react'
import Spacings from 'src/figma/tokens/Spacings'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import { Language } from '../interfaces/LanguageType'
import MUIRichTextEditor from 'mui-rte'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

const createAdornmentWithLoading = (adornment: JSX.Element, loading?: boolean) => {
  if (!loading) return <StyledBox style={{ padding: '14.5px 14px' }}>{adornment}</StyledBox>

  return (
    <StyledBox direction="row" align="center" justify="center">
      <StyledBox style={{ padding: '14.5px 14px' }}>{adornment}</StyledBox>
    </StyledBox>
  )
}

const getLabelText = (language: Language, textkey?: TextType, text?: string) => {
  if (!text && textkey) return getText(textkey, language)

  return text
}

const StyledTextField = styled(TextField)<{
  containerStyles: CSSObject
  inputStyles: CSSObject
  hasFocusedLabelStyle: boolean
}>`
  &&& {
    flex: '100%';
    ${({ containerStyles }) =>
      css`
        ${containerStyles}
      `}
    input {
      ${({ inputStyles }) =>
        css`
          ${inputStyles}
        `}
    }

    .MuiInputBase-formControl.MuiInputBase-multiline {
      padding: 14px;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: ${BorderRadius.soft};
      overflow: hidden;
    }
    label.Mui-focused {
      ${({ hasFocusedLabelStyle }) =>
        hasFocusedLabelStyle &&
        css`
          background-color: white;
          padding: ${Spacings.minimum};
          border-radius: ${BorderRadius.rounded};
        `}
    }
  }
`

export function TextFieldController<T extends Record<string, unknown>>({
  name,
  control,
  labelTextKey,
  labelText,
  multiline,
  adornment,
  onBlur,
  type,
  disabled,
  rows,
  loading,
  containerStyles,
  inputStyles,
  hasFocusedLabelStyle = false,
  autoComplete = 'off'
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: TextType
  multiline?: boolean
  adornment?: JSX.Element
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  type?: HTMLInputTypeAttribute
  disabled?: boolean
  rows?: number
  loading?: boolean
  labelText?: string
  containerStyles?: CSSObject
  inputStyles?: CSSObject
  hasFocusedLabelStyle?: boolean
  autoComplete?: string
}) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <StyledTextField
            rows={rows}
            disabled={disabled}
            fullWidth
            value={value}
            onBlur={onBlur}
            onChange={(event) => {
              if (type === 'number') {
                // @ts-ignore
                const _event = { ...event, target: { value: event.target.valueAsNumber } }
                return onChange(_event)
              }

              return onChange(event)
            }}
            label={getLabelText(language, labelTextKey, labelText)}
            error={Boolean(errors[name]?.message)}
            helperText={errors[name]?.message as React.ReactNode}
            multiline={multiline}
            type={type}
            InputProps={adornment ? { endAdornment: createAdornmentWithLoading(adornment, loading) } : {}}
            containerStyles={containerStyles as CSSObject}
            inputStyles={inputStyles as CSSObject}
            hasFocusedLabelStyle={hasFocusedLabelStyle}
            autoComplete={autoComplete}
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}

export const DatePickerController = <T extends Record<string, unknown>>({
  name,
  disabled,
  icon: Icon = Event,
  labelTextKey,
  labelText,
  control,
  readOnly,
  loading,
  containerStyles,
  inputStyles,
  hasFocusedLabelStyle = false,
  placeholder
}: {
  name: Path<T>
  value?: dayjs.Dayjs | null
  onChange?: (value: dayjs.Dayjs) => unknown
  disabled?: boolean
  labelTextKey?: TextType
  labelText?: string
  control: Control<T>
  icon?: React.ElementType
  readOnly?: boolean
  loading?: boolean
  containerStyles?: CSSObject
  inputStyles?: CSSObject
  hasFocusedLabelStyle?: boolean
  placeholder?: string
}) => {
  const language = useLanguage()

  const IconWithLoading = useCallback(() => createAdornmentWithLoading(<Icon />, loading), [])

  return (
    <Controller
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <DatePicker
            label={getLabelText(language, labelTextKey, labelText)}
            value={value}
            readOnly={readOnly}
            onChange={onChange}
            disabled={disabled}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                containerStyles={containerStyles as CSSObject}
                inputStyles={inputStyles as CSSObject}
                hasFocusedLabelStyle={hasFocusedLabelStyle}
                placeholder={placeholder}
                error={Boolean(errors[name]?.message)}
                helperText={errors[name]?.message as React.ReactNode}
              />
            )}
            components={{
              OpenPickerIcon: IconWithLoading
            }}
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}

export function AutoCompleteController<T extends Record<string, unknown>>({
  name,
  control,
  placeholderTextKey,
  labelTextKey,
  labelText,
  options,
  onClose,
  disabled,
  containerStyles,
  inputStyles,
  hasFocusedLabelStyle
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: TextType
  labelText?: string
  placeholderTextKey?: TextType
  options: Required<Pick<{ label: string; value: string | number | Record<string, unknown> }, 'label' | 'value'>>[]
  onClose?: () => void
  disabled?: boolean
  containerStyles?: CSSObject
  inputStyles?: CSSObject
  hasFocusedLabelStyle?: boolean
}) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange, value = '' }, formState: { errors } }) => {
        const option = options?.find((option) => {
          if (typeof option.value === 'object') {
            return JSON.stringify(option.value) === JSON.stringify(value)
          }
          return option.value === value
        }) || { label: '', value: '' }

        return (
          <Autocomplete
            fullWidth
            value={option}
            options={options}
            onClose={onClose}
            disabled={disabled}
            disableClearable={true}
            getOptionLabel={(option) => option.label}
            onChange={(event, { value = '' }) => onChange(value)}
            isOptionEqualToValue={(option, value) => {
              if (typeof option.value === 'object') {
                return value.value === '' || JSON.stringify(option.value) === JSON.stringify(value.value)
              }
              return value.value === '' || option.value === value.value
            }}
            renderInput={(props) => (
              <StyledTextField
                {...props}
                label={getLabelText(language, labelTextKey, labelText)}
                placeholder={placeholderTextKey ? getText(placeholderTextKey, language) : undefined}
                error={Boolean(errors[name]?.message)}
                helperText={errors[name]?.message as React.ReactNode}
                containerStyles={containerStyles as CSSObject}
                inputStyles={inputStyles as CSSObject}
                hasFocusedLabelStyle={hasFocusedLabelStyle as boolean}
              />
            )}
            renderOption={(props, _option) => {
              if (option.value === '') props['aria-selected'] = false

              return (
                <li {...props} key={props.id}>
                  {_option.label}
                </li>
              )
            }}
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}

export function AutoCompleteChipController<T extends Record<string, unknown>>({
  name,
  control,
  placeholderTextKey,
  labelTextKey,
  labelText,
  options,
  onClose,
  disabled,
  containerStyles,
  inputStyles,
  hasFocusedLabelStyle,
  onChipRemove
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: TextType
  labelText?: string
  placeholderTextKey?: TextType
  options?: { value: string | number; label: string }[]
  onClose?: () => void
  disabled?: boolean
  containerStyles?: CSSObject
  inputStyles?: CSSObject
  hasFocusedLabelStyle?: boolean
  onChipRemove?: (value: unknown) => void
}) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange, value = [] }, formState: { errors } }) => {
        return (
          <Autocomplete
            multiple
            fullWidth
            onClose={onClose}
            disabled={disabled}
            disableClearable={true}
            sx={{ '.MuiOutlinedInput-root': { flex: '100%' }, height: 'auto' }}
            onChange={onChange}
            options={options || ([] as any)}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index: number) => {
                const chipValue = typeof option !== 'string' ? option?.label : option
                return (
                  <StyledBox key={`${chipValue}-${index}`} direction="row" flexWrap="wrap" gap={Spacings.minimum}>
                    <Chip clickable label={chipValue} {...getTagProps({ index })} />
                  </StyledBox>
                )
              })
            }
            renderInput={(props) => (
              <StyledTextField
                {...props}
                label={getLabelText(language, labelTextKey, labelText)}
                placeholder={placeholderTextKey ? getText(placeholderTextKey, language) : undefined}
                error={Boolean(errors[name]?.message)}
                helperText={errors[name]?.message as React.ReactNode}
                containerStyles={containerStyles as CSSObject}
                inputStyles={inputStyles as CSSObject}
                hasFocusedLabelStyle={hasFocusedLabelStyle as boolean}
              />
            )}
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}

const StyledChipSelectLabel = styled(InputLabel)`
  &:not(.MuiInputLabel-shrink) {
    padding: 5px !important;
  }
`

const StyledChipSelect = styled(Select)<{ hasValue?: boolean }>`
  padding: ${({ hasValue }) => (hasValue ? '0px' : '5px')};
`

export function SelectChipController<T extends Record<string, unknown>>({
  name,
  control,
  options,
  disabled,
  labelTextKey,
  labelText,
  defaultValue,
  onBlur,
  onChipRemove
}: {
  control: Control<T>
  defaultValue?: string[]
  disabled?: boolean
  labelText?: string
  labelTextKey?: TextType
  name: Path<T>
  onBlur?: (e: React.ChangeEvent<HTMLElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLElement>) => void
  loading?: boolean
  options: { value: string | number; label: string }[]
  onChipRemove?: (value: unknown) => void
}) {
  const language = useLanguage()
  const selectRef = useRef<HTMLElement>(null)

  return (
    <Controller
      render={({ field: { onChange, value = [] } }) => {
        return (
          <FormControl fullWidth>
            <StyledChipSelectLabel id={`${name}Label`}>{labelText ? labelText : labelTextKey && getText(labelTextKey, language)}</StyledChipSelectLabel>
            <StyledChipSelect
              hasValue={!!(value as string[]).length}
              onBlur={onBlur}
              inputRef={selectRef}
              defaultValue={defaultValue}
              disabled={disabled}
              id={name}
              labelId={`${name}Label`}
              label={labelText ? labelText : labelTextKey && getText(labelTextKey, language)}
              value={value}
              multiple
              style={{ height: 'auto' }}
              onChange={onChange}
              renderValue={(values) => {
                const selected = values as string[]

                return (
                  <StyledBox direction="row" flexWrap="wrap" gap={Spacings.minimum}>
                    {selected.map((selectedValue) => (
                      <Chip
                        clickable
                        deleteIcon={
                          <Cancel
                            onMouseDown={(event) => {
                              event.stopPropagation()

                              // When stopping the propagation the components looses its focus state
                              // we need to refocus it after a slight delay
                              setTimeout(() => selectRef.current?.focus(), 10)
                            }}
                          />
                        }
                        onDelete={(e) => {
                          e?.stopPropagation()

                          if (onChipRemove) {
                            return onChipRemove(selectedValue)
                          }

                          onChange((value as string[]).filter((v) => v !== selectedValue))
                        }}
                        key={selectedValue}
                        label={options.find((v) => v.value === selectedValue)?.label ?? selectedValue}
                      />
                    ))}
                  </StyledBox>
                )
              }}
            >
              {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </StyledChipSelect>
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}

export function SelectController<T extends Record<string, unknown>>({
  name,
  control,
  options,
  disabled,
  labelTextKey,
  labelText,
  defaultValue,
  onBlur,
  readOnly
}: {
  control: Control<T>
  defaultValue?: string[]
  disabled?: boolean
  labelText?: string
  labelTextKey?: TextType
  name: Path<T>
  onBlur?: (e: React.ChangeEvent<HTMLElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLElement>) => void
  loading?: boolean
  options: { value: string | number; label: string; disabled?: boolean }[]
  onChipRemove?: (value: unknown) => void
  readOnly?: boolean
}) {
  const language = useLanguage()
  const selectRef = useRef<HTMLElement>(null)

  return (
    <Controller
      render={({ field: { onChange, value = [] } }) => {
        return (
          <FormControl fullWidth>
            <InputLabel id={`${name}Label`}>{labelText ? labelText : labelTextKey && getText(labelTextKey, language)}</InputLabel>
            <Select
              readOnly={readOnly}
              onBlur={onBlur}
              inputRef={selectRef}
              defaultValue={defaultValue}
              disabled={disabled}
              id={name}
              labelId={`${name}Label`}
              label={labelText ? labelText : labelTextKey && getText(labelTextKey, language)}
              value={value as ''}
              style={{ height: 'auto' }}
              onChange={onChange}
            >
              {options.map(({ value, label, disabled }) => (
                <MenuItem key={value} value={value} disabled={disabled}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}

export function CheckboxController<T extends Record<string, unknown>>({
  name,
  control,
  onChange: _onChange,
  labelTextKey,
  labelText,
  labelPlacement = 'end',
  justifyContent = 'flex-start',
  disabled
}: {
  name: Path<T>
  control: Control<T>
  onChange?: (checked: boolean) => void
  labelTextKey?: TextType
  labelText?: string
  labelPlacement?: FormControlLabelProps['labelPlacement']
  justifyContent?: React.CSSProperties['justifyContent']
  disabled?: boolean
}) {
  const language = useLanguage()

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      label={getLabelText(language, labelTextKey, labelText)}
      control={
        <Controller
          render={({ field: { onChange, value } }) => (
            <Checkbox
              disabled={disabled}
              checked={Boolean(value)}
              onChange={(...event) => {
                onChange(...event)
                _onChange && _onChange(event[1])
              }}
            />
          )}
          control={control}
          name={name}
        />
      }
      sx={{ marginRight: 0, marginLeft: 0, justifyContent }}
    />
  )
}

export function RadioGroupController<T extends Record<string, unknown>>({
  name,
  control,
  options = []
}: {
  name: Path<T>
  control: Control<T>
  options: { value: string | number; label: string }[]
}) {
  return (
    <Controller
      render={({ field: { onChange } }) => {
        return (
          <FormControl>
            <RadioGroup onChange={onChange}>
              {options.map(({ value, label }) => (
                <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
              ))}
            </RadioGroup>
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}

export function SwitchController<T extends Record<string, unknown>>({
  name,
  control,
  onBlur,
  labelTextKey,
  labelPlacement = 'end',
  justifyContent = 'flex-start',
  disabled
}: {
  name: Path<T>
  control: Control<T>
  onBlur?: () => void
  labelTextKey?: TextType
  labelPlacement?: FormControlLabelProps['labelPlacement']
  justifyContent?: React.CSSProperties['justifyContent']
  disabled?: boolean
}) {
  const language = useLanguage()

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      label={labelTextKey ? getText(labelTextKey, language) : ''}
      control={
        <Controller
          render={({ field: { onChange, value } }) => (
            <MaterialSwitchExperimental disabled={disabled} checked={Boolean(value)} onChange={onChange} onBlur={onBlur} />
          )}
          control={control}
          name={name}
        />
      }
      sx={{ marginRight: 0, marginLeft: 0, justifyContent }}
    />
  )
}

export function EditorController<T extends Record<string, unknown>>({
  name,
  control,
  labelTextKey,
  labelText,
  disabled,
  loading
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: TextType
  disabled?: boolean
  loading?: boolean
  labelText?: string
}) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange, value } }: any) => {
        return (
          <EditorWrapper fullWidth fullPadding spacing={Spacings.min}>
            <MUIRichTextEditor
              onChange={onChange}
              {...({ editorState: value } as any)}
              label={labelTextKey ? getText(labelTextKey, language) : ''}
              controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'numberList', 'bulletList', 'quote', 'code', 'link']}
            />
          </EditorWrapper>
        )
      }}
      control={control}
      name={name}
    />
  )
}

const EditorWrapper = styled(StyledBox)`
  border-radius: ${BorderRadius.soft};
  border: 1px solid rgba(0, 0, 0, 0.23);
  * {
    position: relative !important;
  }
  .MUIRichTextEditor-container-46 {
    display: flex;
    flex-direction: column;
    gap: ${Spacings.min};
  }
  .MUIRichTextEditor-container-2 {
    margin: 0 !important;
  }
  #mui-rte-toolbar {
    display: flex;

    padding-bottom: ${Spacings.min} !important;
    button {
      padding: 4px;
    }
  }
  #mui-rte-container {
    margin: 0 !important;
  }
`

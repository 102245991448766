import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import React from 'react'
import Colours from '../../figma/tokens/Colours'
import { MUI_SWITCH_BASE_PX } from '../Constants'

const MaterialSwitchExperimental: React.FC<SwitchProps> = (props) => {
  return <StyledSwitch type="checkbox" size="small" disableRipple value={props.checked} checked={props.checked} {...props} />
}

const StyledSwitch = styled((props: SwitchProps) => <Switch {...props} />)(({ theme }) => ({
  width: MUI_SWITCH_BASE_PX + 20,
  height: MUI_SWITCH_BASE_PX + 4,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: `300ms`,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: `${Colours.white} !important`,
      opacity: 1,
      '& + .MuiSwitch-track': {
        backgroundColor: `${Colours.green} !important`,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: `${Colours.gray} !important`,
        opacity: 1
      }
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: `${Colours.gray} !important`,
      opacity: 1
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: MUI_SWITCH_BASE_PX,
    height: MUI_SWITCH_BASE_PX
  },
  '& .MuiSwitch-track': {
    border: `1px solid ${Colours.gray} !important`,
    borderRadius: (MUI_SWITCH_BASE_PX + 4) / 2,
    backgroundColor: `${Colours.grayLight} !important`,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))
export default MaterialSwitchExperimental

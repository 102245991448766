const routePaths = {
  entry: '/',
  companies: '/companies',
  employees: '/employees',
  activity: '/activity',
  billing: '/billing',
  templates: '/templates',
  users: '/users',
  documentation: '/documentation'
}

export default routePaths

import routePaths from './config/RoutePaths'
import loadable from '@loadable/component'
import DevHarbourLoader from './components/DevHarbourLoader'
import { COMPANIES, EMPLOYEES, USERS } from './Constants'

const Companies = loadable(() => import('./pages/Companies'), {
  fallback: <DevHarbourLoader />
})

const Employees = loadable(() => import('./pages/Employees'), {
  fallback: <DevHarbourLoader />
})

const Users = loadable(() => import('./pages/Users'), {
  fallback: <DevHarbourLoader />
})

const RouterPublicRoutes = [
  { path: routePaths.companies, component: Companies, title: COMPANIES },
  { path: routePaths.employees, component: Employees, title: EMPLOYEES },
  { path: routePaths.users, component: Users, title: USERS }
]

export default RouterPublicRoutes

import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import { memo, SVGProps } from 'react'
import styled from 'styled-components'
import Spacings from '../figma/tokens/Spacings'
import SVGContainer from 'src/moesia/components/SVGContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import { SIDEBAR_BUTTON_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import { useNavigate } from 'react-router-dom'

type Props = {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  url: string
}

const SidebarButton = ({ icon, textKey, url }: Props) => {
  const navigate = useNavigate()
  return (
    <SidebarButtonWrapper
      fullWidth
      direction="row"
      align="center"
      gap={Spacings.min}
      justify="flex-start"
      left={Spacings.tiny}
      onClick={() => navigate(url)}
    >
      <SVGContainer SVG={icon} />
      <TextContainer textKey={textKey} />
    </SidebarButtonWrapper>
  )
}

export default memo(SidebarButton)

const SidebarButtonWrapper = styled(StyledBox)`
  height: ${SIDEBAR_BUTTON_HEIGHT};
  cursor: pointer;
  border-radius: ${BorderRadius.soft};
  &:hover {
    background-color: ${FigmaColors.surfaceOnWhite};
    svg path {
      fill: ${FigmaColors.grayDark} !important;
    }
  }
`

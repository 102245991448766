import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useAppState } from './components/AuthProvider'
import Overview from './pages/Overview'
import routePaths from './config/RoutePaths'
import RouterPrivateRoutes from './RouterPrivateRoutes'
import LoginPage from './pages/LoginPage'

const Router: React.FC = () => {
  const { isAuthenticated = false } = useAppState()

  return (
    <AnimatePresence initial={false}>
      <BrowserRouter>
        <Routes>
          <Route path={routePaths.entry} element={isAuthenticated ? <Overview /> : <LoginPage />} />
          {RouterPrivateRoutes.map(({ component: Component, path, title }) => (
            <Route
              path={path}
              key={path}
              element={
                isAuthenticated ? (
                  <>
                    <Component />
                    <Helmet title={title} />
                  </>
                ) : (
                  <Navigate to={routePaths.entry} />
                )
              }
            />
          ))}
          <Route path="*" element={!isAuthenticated ? <Navigate to={routePaths.entry} /> : <Overview />} />
        </Routes>
      </BrowserRouter>
    </AnimatePresence>
  )
}

export default React.memo(Router)

import React, { SVGProps } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'

type Props = {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  iconMobile: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  arrow?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  url?: string
  onClick?: () => void
}

const SidebarAccountMenuItem: React.FC<Props> = ({ icon, iconMobile, textKey, arrow, onClick }) => {
  const isMobile = useIsMobile()

  return (
    <MenuItem
      onClick={onClick}
      classname="wrapper"
      direction="row"
      fullWidth
      fullPadding
      spacing={Spacings.min}
      align="center"
      justify="space-between"
    >
      <StyledBox direction="row" gap={Spacings.min} align="center">
        <SVGContainer SVG={isMobile ? iconMobile : icon} className="icon" />
        <TextContainer textKey={textKey} />
      </StyledBox>
      <StyledBox align="center">{!!arrow && <SVGContainer SVG={arrow} className="icon" />}</StyledBox>
    </MenuItem>
  )
}

export default SidebarAccountMenuItem

const MenuItem = styled(StyledBox)`
  cursor: pointer;
  .icon {
    max-height: 18px !important; //TODO remove when we fix the svg container to not add additional height of the span
  }
`

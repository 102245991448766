import React from 'react'
import { Helmet } from 'react-helmet-async'

const HelmetMaterialUIIcons: React.FC = () => {
  return (
    <Helmet>
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    </Helmet>
  )
}
export default HelmetMaterialUIIcons

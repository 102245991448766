/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import axios, { AxiosInstance } from 'axios'
import { User } from 'src/components/AuthProvider'

export const backofficeApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKOFFICE_API_URL || 'http://localhost:8080',
  headers: {
    'Content-Type': 'application/json'
  }
})

const COMPANY_ROUTE = '/company'
const EMPLOYEE_ROUTE = '/employee'
const AUTH_ROUTE = '/auth'
const OVERVIEW_ROUTE = '/overview'
const USER_ROUTE = '/user'

export const getCompanies = (user: User, filter: any) =>
  backofficeApi.get(`${COMPANY_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const createCompany = (user: User, data: any) =>
  backofficeApi.post(`${COMPANY_ROUTE}/`, data).then(({ data }) => data)
export const getCompanyById = (user: User, companyId: string) =>
  backofficeApi.get(`${COMPANY_ROUTE}/${companyId}`).then(({ data }) => data)
export const updateCompany = (user: User, companyId: string, data: any) =>
  backofficeApi.put(`${COMPANY_ROUTE}/${companyId}`, data).then(({ data }) => data)
export const getEmployees = (user: User, filter: any) =>
  backofficeApi.get(`${EMPLOYEE_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const createEmployee = (user: User, data: any) =>
  backofficeApi.post(`${EMPLOYEE_ROUTE}/`, data).then(({ data }) => data)
export const getEmployeeById = (user: User, employeeId: string) =>
  backofficeApi.get(`${EMPLOYEE_ROUTE}/${employeeId}`).then(({ data }) => data)
export const updateEmployee = (user: User, employeeId: string, data: any) =>
  backofficeApi.put(`${EMPLOYEE_ROUTE}/${employeeId}`, data).then(({ data }) => data)
export const signup = (data: any) => backofficeApi.post(`${AUTH_ROUTE}/signup`, data).then(({ data }) => data)
export const signin = (data: any) => backofficeApi.post(`${AUTH_ROUTE}/signin`, data).then(({ data }) => data)
export const refreshToken = (user: User, data: any) =>
  backofficeApi.post(`${AUTH_ROUTE}/refreshtoken`, data).then(({ data }) => data)
export const getOverview = (user: User, filter: any) =>
  backofficeApi.get(`${OVERVIEW_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const getUsers = (user: User, filter: any) =>
  backofficeApi.get(`${USER_ROUTE}/`, { params: filter }).then(({ data }) => data)

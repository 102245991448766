import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import userMenuArrowLeft from 'src/figma/images/userMenuArrowLeft'
import userMenuLogoutIcon from 'src/figma/images/userMenuLogoutIcon'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import { generateUUID } from 'src/helpers/Common'
import { getLanguageMenuContent, getSelectedLanguage, isLanguageSelected, Language } from 'src/helpers/SidebarHelper'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import { SIDEBAR_ACCOUNT_MENU_WIDTH } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import { useAppState } from './AuthProvider'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import SidebarAccountMenuItem from './SidebarAccountMenuItem'
import SidebarLanguageMenuItem from './SidebarLanguageMenuItem'

type Props = {
  setIsOpenLanguageMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const SidebarLanguageMenu: React.FC<Props> = ({ setIsOpenLanguageMenu }) => {
  const languageMenuItems = useMemo(() => getLanguageMenuContent(), [])

  const { state, setState } = useAppState()

  const selectedLanguage = useMemo(() => getSelectedLanguage(state), [state])

  const onSelectLanguage = (language: Language) => {
    const newLanguage = { ...state, language }
    setState(newLanguage)
    setIsOpenLanguageMenu(false)
  }

  return (
    <AnimatePresence mode="wait">
      <Wrapper key={generateUUID()} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <MenuUserInformation iconType={UserInformationType.NONE} />
        <StyledBox fullWidth fullPadding spacing={Spacings.min} gap={Spacings.min}>
          <ArrowWrapper onClick={() => setIsOpenLanguageMenu(false)} fullWidth>
            <SVGContainer SVG={userMenuArrowLeft} />
          </ArrowWrapper>
          <StyledBox fullWidth left right spacing={Spacings.minimum}>
            {!!languageMenuItems &&
              languageMenuItems.map(({ flag, textKey, value }) => {
                const isSelectedLanguage = isLanguageSelected(value, selectedLanguage)
                return (
                  <SidebarLanguageMenuItem
                    onClick={() => onSelectLanguage(value)}
                    key={generateUUID()}
                    flag={flag}
                    textKey={textKey}
                    isSelectedLanguage={isSelectedLanguage}
                  />
                )
              })}
          </StyledBox>
          <SidebarAccountMenuItem
            icon={userMenuLogoutIcon}
            iconMobile={userMenuLogoutIcon}
            textKey={Texts.UserMenuLogOutTitle}
          />
        </StyledBox>
      </Wrapper>
    </AnimatePresence>
  )
}

export default SidebarLanguageMenu

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: ${SIDEBAR_ACCOUNT_MENU_WIDTH};
  position: absolute;
  overflow: hidden;
  z-index: 999;
  border-radius: ${BorderRadius.soft};
  bottom: 30px; //TODO check for better solution
  left: 300px; //TODO check for better solution
  background-color: ${FigmaColors.white};
  box-shadow: ${Shadows.baseHeavy};
`

const ArrowWrapper = styled(StyledBox)`
  cursor: pointer;
`

import React, { useContext, useEffect, useState } from 'react'
import { UserType } from 'src/enums/UserType'
import TokenService from '../helpers/TokenHelper'
import { Language } from 'src/moesia/interfaces/LanguageType'

export const DEFAULT_STATE: State = {
  language: 'en',
  user: null
}

const AuthContext = React.createContext<{
  isAuthenticated: boolean
  state: State
  setState: (state: State) => void
}>({
  state: DEFAULT_STATE,
  setState: () => ({}),
  isAuthenticated: false
})

export function useAppState() {
  return useContext(AuthContext)
}

export type User = {
  name: string
  email: string
  password: string
  company: string[]
  role: UserType
  refreshToken: string
  accessToken: string
}

export type State = {
  language: Language
  user: User | null
}

export function AuthProvider({ children }: any) {
  const [state, setState] = useState(DEFAULT_STATE)

  useEffect(() => {
    const user = TokenService.getLocalStorageUser()
    if (user) {
      setState({
        ...DEFAULT_STATE,
        user
      })
    }
    if (!user) {
      setState({
        ...state,
        user: null
      })
    }
  }, [])

  return (
    <AuthContext.Provider value={{ isAuthenticated: !!state.user, state, setState }}>{children}</AuthContext.Provider>
  )
}

import { backofficeApi } from './ApiHandler'
import TokenService from './TokenHelper'
import { useAppState, State } from 'src/components/AuthProvider'

const setupInterceptors = () => {
  backofficeApi.interceptors.request.use(
    (config: any) => {
      const token = TokenService.getLocalAccessToken()
      if (token) {
        config.headers['x-access-token'] = token
      }
      return config
    },
    (error: any) => {
      return Promise.reject(error)
    }
  )

  backofficeApi.interceptors.response.use(
    (res: any) => {
      return res
    },
    async (err: any) => {
      const originalConfig = err.config
      if (originalConfig?.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true
          const { state, setState } = useAppState()

          try {
            const rs = await backofficeApi.post('/auth/refreshtoken', {
              refreshToken: TokenService.getLocalRefreshToken()
            })

            const { accessToken, refreshToken } = rs.data
            const successUserState = { ...state, user: { ...state.user, accessToken, refreshToken } } as State
            setState(successUserState)
            TokenService.updateLocalAccessToken(accessToken)

            return backofficeApi(originalConfig)
          } catch (_error) {
            TokenService.removeUser()
            const failureUserState = { ...state, user: null }
            setState(failureUserState)
            return console.error(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )
}

export default setupInterceptors

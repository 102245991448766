/* eslint-disable max-len */
// USED IN MaterialSwitch
export const MUI_SWITCH_BASE_PX = 22

// Pages max-width
export const PAGE_WRAPPER_MAX_WIDTH = '1440px'

// Links
export const JOBS_BG_LINK = 'https://www.jobs.bg/en/front_job_search.php?company_id=303291'
export const DEV_HARBOUR_GOOGLE_MAPS_LINK =
  'https://www.google.com/maps/place/%D1%83%D0%BB.+%22%D0%91%D1%83%D0%B4%D0%B0%D0%BF%D0%B5%D1%89%D0%B0%22+82,+1202+%D0%A1%D0%BE%D1%84%D0%B8%D1%8F+%D1%86%D0%B5%D0%BD%D1%82%D1%8A%D1%80,+%D0%A1%D0%BE%D1%84%D0%B8%D1%8F/@42.7064958,23.326882,17z/data=!3m1!4b1!4m5!3m4!1s0x40aa856174db28b3:0xe1091e6a69719467!8m2!3d42.7064958!4d23.326882'
export const DEV_HARBOUR_LINKEDIN = 'https://www.linkedin.com/in/office-dev-harbour-20a10a215/'
export const DEV_HARBOUR_FACEBOOK = 'https://www.facebook.com/Dev-Harbor-101190358831949'
export const HRISTO_STAMENOV_LINKEDIN = 'https://www.linkedin.com/in/hristo-stamenov-29b97a29/'
export const VASSIL_NIKOLOV_LINKEDIN = 'https://www.linkedin.com/in/vassil-nikolov-a1b66183/'

// Text line heights
export const LINE_HEIGHTS = {
  tiny: '18px',
  small: '20px',
  medium: '28px',
  large: '40px',
  big: '48px'
}

// Animations values
export const REGULAR_TRANSFORM = 'scale(1.05)'
export const REGULAR_TRANSITION = 'all 0.3s ease-in-out'

import React from 'react'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import ExperimentalImageContainer from 'src/moesia/components/ExperimentalImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { PAGE_WRAPPER_MAX_WIDTH } from 'src/moesia/Constants'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { FigmaImageType, TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import { Line } from './Line'
import MobileHeader from './MobileHeader'
import Sidebar from './Sidebar'

type Props = {
  pageHeading: TextType
  pageSubheading: TextType
  children: React.ReactNode
  showHeading: boolean
  backgroundImage?: FigmaImageType
  fullBackgroundImage?: boolean
}

const BackOfficeLayout: React.FC<Props> = ({
  pageHeading,
  pageSubheading,
  children,
  showHeading,
  backgroundImage,
  fullBackgroundImage
}) => {
  const isMobile = useIsMobile()

  return (
    <Wrapper fullWidth>
      {!isMobile && (
        <InnerWrapper fullWidth direction="row">
          <Sidebar />
          <Page fullWidth>
            {showHeading && (
              <StyledBox fullWidth fullPadding spacing={Spacings.big} gap={Spacings.min}>
                <TextContainer textKey={pageHeading} />
                <TextContainer textKey={pageSubheading} />
                <Line fullWidth />
              </StyledBox>
            )}

            <StyledBox fullWidth left right align="center" spacing={Spacings.big}>
              {children}
            </StyledBox>
          </Page>
        </InnerWrapper>
      )}
      {isMobile && (
        <InnerWrapper fullWidth position="relative">
          {backgroundImage && (
            <StyledImageContainer
              imageKey={backgroundImage}
              containerStyle={fullBackgroundImage ? { position: 'sticky', height: 'auto', top: 0 } : {}}
            />
          )}
          <MobileWrapper>
            <MobileHeader />
            <StyledBox>{children}</StyledBox>
          </MobileWrapper>
        </InnerWrapper>
      )}
    </Wrapper>
  )
}

export default React.memo(BackOfficeLayout)

const Wrapper = styled(StyledBox)`
  max-height: 100vh;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
`

const Page = styled(StyledBox)`
  background: ${FigmaColors.backgroundGazelle};
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: ${PAGE_WRAPPER_MAX_WIDTH};
`

const InnerWrapper = styled(StyledBox)`
  overflow-y: auto;
  overflow-x: hidden;
`
const StyledImageContainer = styled(ExperimentalImageContainer)`
  z-index: -1;
  width: 100vw;
  min-width: 100vw !important;
`
const MobileWrapper = styled(StyledBox)`
  z-index: 1;
  position: absolute;
`

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      // eslint-disable-next-line eqeqeq
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const createKeyIndex = (index: number, location: string): string => {
  if (location === '') {
    return `${index}`
  }
  return `${index}-${location}`
}

export const getRawContent = (text: string) => {
  return {
    blocks: [
      {
        data: {},
        depth: 0,
        entityRanges: [],
        inlineStyleRanges: [],
        key: '2vm6d',
        text,
        type: 'normal'
      }
    ],
    entityMap: {}
  }
}

import React from 'react'
import styled, { StyledComponent } from 'styled-components'
import { EN } from '../../Constants'
import { getText } from '../../figma/helpers/TextRepository'
import useLanguage from '../../hooks/useLanguage'
import useTextVariant from '../hooks/useTextVariant'
import { TextType } from '../interfaces/FigmaTypes'
import { Language } from '../interfaces/LanguageType'

export type TextContainerProps = {
  textKey: TextType
  text?: string
  className?: string
  style?: React.CSSProperties
  element?: React.FC<{ style: React.CSSProperties }>
  ignoreNewLine?: boolean
}

const TextContainer: React.FC<TextContainerProps> = ({ textKey, text, className, style, element = Span, ignoreNewLine }) => {
  return (
    <div className={className}>
      <LangText text={text} textKey={textKey} style={style} element={element} ignoreNewLine={ignoreNewLine} language={useLanguage()} />
    </div>
  )
}

export default TextContainer

export type LangTextProps = {
  textKey: TextType
  text?: string
  style?: React.CSSProperties
  element: React.FC<{ style: React.CSSProperties }> | StyledComponent<'span', any, Record<string, unknown>, never>
  ignoreNewLine?: boolean
  language: Language
}

const LangText: React.FC<LangTextProps> = ({ text, textKey, language, style, ignoreNewLine, element }) => {
  language = language || EN
  const variantStyle = useTextVariant(textKey)
  text = text || getText(textKey, language) || getText(textKey, EN)
  const texts = ignoreNewLine ? [text] : text.split('\n')

  return React.createElement(element, { style: { ...variantStyle.style, ...style } }, texts)
}

const Span = styled.span`
  display: flex;
  flex-direction: column;
`

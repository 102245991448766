import React, { memo, useState } from 'react'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import SVGContainer from 'src/moesia/components/SVGContainer'
import navigationLogo from 'src/figma/images/navigationLogo'
import TextContainer from 'src/moesia/components/TextContainer'
import { Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import SidebarAccountMenu from './SidebarAccountMenu'
import { SIDEBAR_ELEMENTS_BORDER, SIDEBAR_HEADER_HEIGHT, SIDEBAR_WIDTH } from 'src/moesia/StrongHardCodedSizes'
import searchIcon from 'src/figma/images/searchIcon'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import SidebarNavigationItems from './SidebarNavigationItems'

const Sidebar = () => {
  const [isOpenAccountMenu, setIsOpenAccountMenu] = useState(false)

  const openAccountMenu = () => {
    setIsOpenAccountMenu(!isOpenAccountMenu)
  }

  return (
    <>
      <SidebarWrapper justify="space-between" align="flex-start">
        <StyledBox fullWidth>
          <Heading fullWidth fullPadding direction="row" spacing={Spacings.tiny} align="center" gap={Spacings.tiny}>
            <SVGContainer SVG={navigationLogo} />
            <StyledBox fullWidth justify="center">
              <TextContainer textKey={Texts.NavigationHeading} />
              <TextContainer textKey={Texts.NavigationSubheading} />
            </StyledBox>
          </Heading>
          <StyledBox fullWidth fullPadding spacing={Spacings.tiny}>
            <StyledSearchBar
              fullWidth
              fullPadding
              direction="row"
              spacing={Spacings.minimum}
              gap={Spacings.tiny}
              align="center"
            >
              <SVGContainer SVG={searchIcon} />
              <TextContainer textKey={Texts.NavigationSearchPlaceholder} />
            </StyledSearchBar>
          </StyledBox>
          <StyledBox fullWidth fullPadding spacing={Spacings.tiny}>
            <SidebarNavigationItems />
          </StyledBox>
        </StyledBox>
        <MenuUserInformation iconType={UserInformationType.OPTIONS} onClick={openAccountMenu} />
      </SidebarWrapper>
      {!!isOpenAccountMenu && <SidebarAccountMenu />}
    </>
  )
}

export default memo(Sidebar)

const SidebarWrapper = styled(StyledBox)`
  max-width: ${SIDEBAR_WIDTH};
  height: 100vh;
  min-width: ${SIDEBAR_WIDTH};
  background-color: ${FigmaColors.white};
  box-shadow: ${Shadows.baseHeavy};
  z-index: 999;
  overflow: hidden;
`
const Heading = styled(StyledBox)`
  height: ${SIDEBAR_HEADER_HEIGHT};
`

const StyledSearchBar = styled(StyledBox)`
  border: ${SIDEBAR_ELEMENTS_BORDER};
  background-color: ${FigmaColors.hoverGray};
  border-radius: ${BorderRadius.soft};
`

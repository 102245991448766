import { memo, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { getText, Texts } from 'src/figma/helpers/TextRepository'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useLanguage from 'src/hooks/useLanguage'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import LoginPageDesktopView from '../components/LoginPageDesktopView'
import LoginPageMobileView from '../components/LoginPageMobileView'
import routePaths from 'src/config/RoutePaths'
import { useLogin } from 'src/helpers/QueryHooks'
import TokenService from 'src/helpers/TokenHelper'
import { useAppState } from 'src/components/AuthProvider'
import { useNavigate } from 'react-router-dom'

export type HomeAuthLoginValues = {
  email: string
  password: string
}

const LoginPage = () => {
  const { state, setState } = useAppState()
  const language = useLanguage()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const login = useLogin()

  const defaultValues = useMemo(
    () => ({
      email: '',
      password: ''
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().required(getText(Texts.LoginEmailErrorRequired, language)),
        password: Yup.string().required(getText(Texts.LoginPasswordErrorRequired, language))
      }),
    [language]
  )

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful }
  } = useForm<HomeAuthLoginValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues)
    }
  }, [defaultValues, isSubmitSuccessful, reset])

  const handleLoginButton = (loginData: HomeAuthLoginValues) => {
    login
      .mutateAsync(loginData)
      .then((user) => {
        if (user.accessToken) {
          TokenService.setUser(user)
        }
        const newState = { ...state, user }
        setState(newState)
        navigate(routePaths.entry)
      })
      .catch((err) => {
        setState({ ...state, user: null })
        console.error(err)
      })
  }

  return (
    <>
      {!isMobile && (
        <LoginPageDesktopView
          handleLogin={handleSubmit((data) => handleLoginButton(data))}
          handleAutoLogin={handleSubmit((data) => handleLoginButton(data))}
          control={control}
        />
      )}
      {isMobile && <LoginPageMobileView control={control} />}
    </>
  )
}

export default memo(LoginPage)

import React from 'react'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import { REGULAR_TRANSITION, REGULAR_TRANSFORM } from 'src/moesia/Constants'
import { OVERVIEW_ACTION_BOX_WIDTH } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import { OverviewNotificationBoxType } from '../helpers/OverviewHelper'

const OverviewNotificationBox: React.FC<OverviewNotificationBoxType> = ({
  icon,
  title,
  notificationMessage,
  emptyStateTextKey
}) => {
  return (
    <Wrapper fullPadding direction="row" justify="space-between" spacing={Spacings.small}>
      <StyledBox gap={Spacings.min}>
        <StyledBox direction="row" gap={Spacings.tiny} align="center">
          <SVGContainer SVG={icon} />
          <TextContainer textKey={title} />
        </StyledBox>
        <StyledBox left={Spacings.large} right={Spacings.small}>
          <TextContainer textKey={emptyStateTextKey} />
        </StyledBox>
      </StyledBox>
    </Wrapper>
  )
}

export default OverviewNotificationBox

const Wrapper = styled(StyledBox)`
  flex: 0 1 ${OVERVIEW_ACTION_BOX_WIDTH};
  background-color: ${FigmaColors.white};
  border-radius: ${BorderRadius.soft};
  cursor: pointer;
  box-shadow: ${Shadows.baseHeavy};
  transition: ${REGULAR_TRANSITION};
  &:hover {
    transform: ${REGULAR_TRANSFORM};
  }
`

/* eslint-disable max-len */

import FigmaColors from 'src/figma/tokens/FigmaColors'

export const PAGE_VIEW_SMALL_PX_SIZE = '362px'
export const PAGE_CONTENT_SIZE = '908px'
export const TAB_PANEL_ITEM_HEIGHT_PX = '3px'

//TERVEL Used in Desktop Navigation
export const TERVEL_NAVIGATION_HEIGHT = '70px'
export const TERVEL_LOGO_MAX_HEIGHT = '60px'
export const TERVEL_LANGUAGE_BAR_MIN_WIDTH = '120px'

//TERVEL HomeContainer
export const TERVEL_PEOPLE_IMAGE_WIDTH = 455
export const TERVEL_CLIENT_CARD_WIDTH = '400px'
export const TERVEL_CLIENT_CARD_HEIGHT = '450px'

//TERVEL Solutions TextBoxes' Width Desktop/Mobile
export const TERVEL_SOLUTIONS_HEADCOMPONENT_TEXTBOX_WIDTH_DESKTOP = '400px'
export const TERVEL_SOLUTIONS_HEADCOMPONENT_TEXTBOX_WIDTH_MOBILE = '100%'

//TERVEL CompanyContainer
export const TERVEL_ABOUT_US_LEFT_PADDING = '120px'

//TERVEL DesktopFooter && MobileFooter
export const TERVEL_DESKTOP_WHITE_BOX_WIDTH = '18px'
export const TERVEL_DESKTOP_WHITE_BOX_HEIGHT = '21px'
export const TERVEL_MOBILE_WHITE_BOX_WIDTH = '12px'
export const TERVEL_MOBILE_WHITE_BOX_HEIGHT = '14px'

//TERVEL Mobile menu
export const TERVEL_MOBILE_MENU_LOGO_WIDTH = '100px'
export const TERVEL_MOBILE_MENU_LOGO_HEIGHT = '80px'
export const TERVEL_MOBILE_MENU_SVG_WIDTH = '12px'
export const TERVEL_MOBILE_MENU_SVG_HEIGHT = '54px'

//TERVEL NewsCard
export const TERVEL_NEWS_CARD_WIDTH = '460px'
export const TERVEL_NEWS_CARD_HEIGHT = '280px'
export const TERVEL_NEWS_CARD_MOBILE_WIDTH = '250px'
export const TERVEL_NEWS_CARD_MOBILE_HEIGHT = '400px'
export const TERVEL_DEFALT_CARDS_TO_SHOW = 6
export const TERVEL_SLICE_START_POSITION = 0
export const TERVEL_NEWS_CARD_FLIP_CARD_PERSPECTIVE = '1000px'
export const TERVEL_NEWS_CARDS_MOBILE_SVG_LOGO_WIDTH = '15%'

//TERVEL Contact Us page background
export const CONTACT_US_BACKGROUND_CLIP_PATH = 'polygon(50% 0%, 82% 2%, 100% 0, 100% 100%, 70% 98%, 50% 100%, 21% 98%, 0 100%, 0 0, 17% 2%)'
export const CONTACT_US_BACKGROUND_CLIP_PATH_MOBILE = 'polygon(50% 0%, 84% 0, 100% 0, 100% 100%, 77% 99%, 50% 100%, 23% 99%, 0 100%, 0 0, 24% 0);'

//TERVEL Contact Us Desktop
export const CONTACT_US_DESKTOP_WRAPPER_HEIGHT = '700px'

//MUI Table
export const MUI_TABLE_SEARCH_HEIGHT = '40px'
export const MUI_TABLE_SEARCH_WIDTH = '300px'
export const TABLE_AVATAR_SIZE = '40px'

export const DEFAULT_LINE_COMPONENT_HEIGHT = '1px'

//BackOffice Sidebar
export const SIDEBAR_WIDTH = '280px'
export const SIDEBAR_HEADER_HEIGHT = '80px'
export const SIDEBAR_ELEMENTS_BORDER = `1px solid ${FigmaColors.stone}`
export const SIDEBAR_ACCOUNT_MENU_WIDTH = '200px'
export const SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE = '30px'
export const SIDEBAR_BUTTON_HEIGHT = '40px'
export const SIDEBAR_DIVIDER_HEIGHT = '70px'
export const MOBILE_HEADER_HEIGHT = '70px'

//Generic Page Modal
export const GENERIC_PAGE_MODAL_MAX_HEIGHT = 'calc(100vh - 150px)'

//Document Component
export const DOCUMENT_COMPONENT_ICON_SIZE = '20px'
export const DOCUMENT_COMPONENT_ICON_WRAPPER_SIZE = '30px'
export const DOCUMENT_WRAPPER_HEIGHT = '40px'

//BO Login Page
export const LOGIN_BUTTON_HEIGHT = '48px'
export const LOGIN_PAGE_MAX_WIDTH = '1200px'
export const LOGIN_PAGE_MAX_HEIGHT = 'calc(100vh - 64px)'

//BackOffice Employee Details
export const EMPLOYEE_DETAILS_MAX_WIDTH = '700px'

//BackOffice Overview Page
export const OVERVIEW_ACTION_BOX_WIDTH = '300px'
export const OVERVIEW_REDIRECTION_BOX_HEIGHT = '120px'

export const SIDEBAR_EMAIL_SPACE_WITHOUT_DETAILS_ICON = '121px' // 96px + 25px(details icon)

//BackOffice Invoices Table
export const INVOICES_TABLE_MIN_HEIGHT = '400px'

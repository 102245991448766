import React, { useMemo, useState } from 'react'
import userMenuArrowLeft from 'src/figma/images/userMenuArrowLeft'
import Spacings from 'src/figma/tokens/Spacings'
import { generateUUID } from 'src/helpers/Common'
import { getAccountMenuContent } from 'src/helpers/SidebarHelper'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import { MOBILE_HEADER_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import MobileLanguageMenu from './MobileLanguageMenu'
import SidebarAccountMenuItem from './SidebarAccountMenuItem'

type Props = {
  setIsOpenAccountMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileAccountMenu: React.FC<Props> = ({ setIsOpenAccountMenu }) => {
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false)

  const userMenuItems = useMemo(() => getAccountMenuContent(), [])

  return (
    <>
      {!isOpenLanguageMenu && (
        <StyledWrapper fullWidth justify="space-between">
          <StyledBox fullWidth fullPadding spacing={Spacings.medium} justify="space-between">
            <StyledBox onClick={() => setIsOpenAccountMenu(false)}>
              <SVGContainer SVG={userMenuArrowLeft} />
            </StyledBox>
            <StyledBox fullWidth gap={Spacings.min}>
              {!!userMenuItems &&
                userMenuItems.map(({ icon, iconMobile, textKey, arrow }, idx) => {
                  return (
                    <SidebarAccountMenuItem
                      onClick={idx === userMenuItems.length - 1 ? () => setIsOpenLanguageMenu(true) : undefined}
                      key={generateUUID()}
                      icon={icon}
                      textKey={textKey}
                      arrow={arrow}
                      iconMobile={iconMobile}
                    />
                  )
                })}
            </StyledBox>
          </StyledBox>
          <MenuUserInformation iconType={UserInformationType.LOG_OUT} />
        </StyledWrapper>
      )}

      {!!isOpenLanguageMenu && <MobileLanguageMenu setIsOpenLanguageMenu={setIsOpenLanguageMenu} />}
    </>
  )
}

export default MobileAccountMenu

const StyledWrapper = styled(StyledBox)`
  height: calc(100vh - (${MOBILE_HEADER_HEIGHT}));
`

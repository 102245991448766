import teamMember1Img from '../images/teamMember1.png'
export const teamMember1 = teamMember1Img
import companiesEmptyPhotoImg from '../images/companiesEmptyPhoto.png'
export const companiesEmptyPhoto = companiesEmptyPhotoImg
import loginPageImageImg from '../images/loginPageImage.png'
export const loginPageImage = loginPageImageImg
import emptyStatePhotoImg from '../images/emptyStatePhoto.png'
export const emptyStatePhoto = emptyStatePhotoImg
import userPhotoImg from '../images/userPhoto.png'
export const userPhoto = userPhotoImg
import noLogoImg from '../images/noLogo.png'
export const noLogo = noLogoImg
import noAvatarPhotoImg from '../images/noAvatarPhoto.png'
export const noAvatarPhoto = noAvatarPhotoImg
import overviewClientsPhotoImg from '../images/overviewClientsPhoto.png'
export const overviewClientsPhoto = overviewClientsPhotoImg
import overviewEmployeesPhotoImg from '../images/overviewEmployeesPhoto.png'
export const overviewEmployeesPhoto = overviewEmployeesPhotoImg
import overviewDocumentationPhotoImg from '../images/overviewDocumentationPhoto.png'
export const overviewDocumentationPhoto = overviewDocumentationPhotoImg
import overviewBackgroundMobileImg from '../images/overviewBackgroundMobile.png'
export const overviewBackgroundMobile = overviewBackgroundMobileImg

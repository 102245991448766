import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import DefaultMaterialTheme from './helpers/DefaultMaterialTheme'
import { QueryClient, QueryClientProvider } from 'react-query'
import setupInterceptors from './helpers/setupInterceptors'

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } })

const root = document.getElementById('root')

if (!!root) {
  ReactDOM.createRoot(root).render(
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={DefaultMaterialTheme}>
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  )
  setupInterceptors()
}

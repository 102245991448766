import { Texts } from 'src/figma/helpers/TextRepository'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import navigationOverviewIcon from 'src/figma/images/navigationOverviewIcon'
import { SVGProps } from 'react'
import navigationActivityIcon from 'src/figma/images/navigationActivityIcon'
import navigationCustomersIcon from 'src/figma/images/navigationCustomersIcon'
import navigationEmployeesIcon from 'src/figma/images/navigationEmployeesIcon'
import navigationBillingIcon from 'src/figma/images/navigationBillingIcon'
import navigationTemplatesIcon from 'src/figma/images/navigationTemplatesIcon'
import userMenuAccountSettingsIcon from 'src/figma/images/userMenuAccountSettingsIcon'
import userMenuUserGuidesIcon from 'src/figma/images/userMenuUserGuidesIcon'
import userMenuLanguageIcon from 'src/figma/images/userMenuLanguageIcon'
import userMenuArrowRight from 'src/figma/images/userMenuArrowRight'
import { BG, EN, SV } from 'src/Constants'
import ukFlag from 'src/figma/images/ukFlag'
import bulgariaFlag from 'src/figma/images/bulgariaFlag'
import swedenFlag from 'src/figma/images/swedenFlag'
import routePaths from 'src/config/RoutePaths'
import userMenuManageUsersIcon from 'src/figma/images/userMenuManageUsersIcon'
import { State } from 'src/components/AuthProvider'
import userMenuAccountSettingsIconMobile from 'src/figma/images/userMenuAccountSettingsIconMobile'
import userMenuUserGuidesIconMobile from 'src/figma/images/userMenuUserGuidesIconMobile'
import userMenuManageUsersIconMobile from 'src/figma/images/userMenuManageUsersIconMobile'
import userMenuLanguagesIconMobile from 'src/figma/images/userMenuLanguagesIconMobile'

export enum SidebarItemType {
  BUTTON = 'Button',
  DIVIDER = 'Divider'
}

type NavigationContent = {
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  url?: string
  type: SidebarItemType
}

export const getSidebarContent = (): NavigationContent[] => [
  {
    icon: navigationOverviewIcon,
    textKey: Texts.NavigationOverviewTitle,
    url: routePaths.entry,
    type: SidebarItemType.BUTTON
  },
  {
    icon: navigationActivityIcon,
    textKey: Texts.NavigationActivityTitle,
    url: routePaths.activity,
    type: SidebarItemType.BUTTON
  },
  {
    textKey: Texts.NavigationGeneralTitle,
    type: SidebarItemType.DIVIDER
  },
  {
    icon: navigationCustomersIcon,
    textKey: Texts.NavigationCustomersTitle,
    url: routePaths.companies,
    type: SidebarItemType.BUTTON
  },
  {
    icon: navigationEmployeesIcon,
    textKey: Texts.NavigationEmployeesTitle,
    url: routePaths.employees,
    type: SidebarItemType.BUTTON
  },
  {
    icon: navigationBillingIcon,
    textKey: Texts.NavigationBillingTitle,
    url: routePaths.billing,
    type: SidebarItemType.BUTTON
  },
  {
    icon: navigationTemplatesIcon,
    textKey: Texts.NavigationTemplatesTitle,
    url: routePaths.templates,
    type: SidebarItemType.BUTTON
  }
]

interface AccountMenuItem {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  iconMobile: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  arrow?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  url?: string
}

export const getAccountMenuContent = (): AccountMenuItem[] => [
  {
    icon: userMenuAccountSettingsIcon,
    iconMobile: userMenuAccountSettingsIconMobile,
    textKey: Texts.UserMenuAccountSettingsTitle
  },
  {
    icon: userMenuManageUsersIcon,
    iconMobile: userMenuManageUsersIconMobile,
    textKey: Texts.UserMenuManageUsersTitle,
    url: routePaths.users
  },
  {
    icon: userMenuUserGuidesIcon,
    iconMobile: userMenuUserGuidesIconMobile,
    textKey: Texts.UserMenuUserGuidesTitle
  },
  {
    icon: userMenuLanguageIcon,
    iconMobile: userMenuLanguagesIconMobile,
    textKey: Texts.UserMenuLanguagesTitle,
    arrow: userMenuArrowRight
  }
]

export type Language = 'sv' | 'en' | 'bg' | 'default'

interface LanguageMenuItem {
  flag: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  value: Language
}

export const getLanguageMenuContent = (): LanguageMenuItem[] => [
  {
    flag: ukFlag,
    textKey: Texts.English,
    value: EN
  },
  {
    flag: swedenFlag,
    textKey: Texts.Swedish,
    value: SV
  },
  {
    flag: bulgariaFlag,
    textKey: Texts.Bulgarian,
    value: BG
  }
]

export const isLanguageSelected = (currentLanguage: Language, selectedLanguage: LanguageMenuItem): boolean => {
  return currentLanguage === selectedLanguage.value
}

export const getSelectedLanguage = (state: State) => {
  const languages = getLanguageMenuContent()
  return languages.find((lang) => lang.value === state.language) || languages[0]
}

import React, { useState } from 'react'
import arrowLeft from 'src/figma/images/arrowLeft'
import navigationMenuIcon from 'src/figma/images/navigationMenuIcon'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import { MOBILE_HEADER_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import MobileMenu from './MobileMenu'

const MobileHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <Wrapper fullWidth>
      {!isMenuOpen && (
        <Wrapper fullWidth fullPadding direction="row" spacing={Spacings.small} align="center" justify="space-between">
          <StyledBox>
            <SVGContainer SVG={arrowLeft} />
          </StyledBox>
          <StyledBox onClick={() => setIsMenuOpen(true)}>
            <SVGContainer SVG={navigationMenuIcon} />
          </StyledBox>
        </Wrapper>
      )}
      {!!isMenuOpen && <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />}
    </Wrapper>
  )
}

export default MobileHeader

const Wrapper = styled(StyledBox)`
  height: ${MOBILE_HEADER_HEIGHT};
  z-index: 999;
  background-color: transparent;
`

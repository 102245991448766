import React from 'react'
import { Images } from 'src/figma/helpers/imageHelper'
import { Texts } from 'src/figma/helpers/TextRepository'
import navigationUserMenuIcon from 'src/figma/images/navigationUserMenuIcon'
import userMenuLogoutIcon from 'src/figma/images/userMenuLogoutIcon'
import Spacings from 'src/figma/tokens/Spacings'
import ExperimentalImageContainer from 'src/moesia/components/ExperimentalImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import { SIDEBAR_ELEMENTS_BORDER, SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import { useAppState } from './AuthProvider'

type Props = {
  menuWithoutIcon?: boolean
  menuWithOptionsIcon?: boolean
  menuWithLogOutIcon?: boolean
  iconType?: UserInformationType
  onClick?: () => void
}

export enum UserInformationType {
  OPTIONS = 'Options',
  LOG_OUT = 'LogOut',
  NONE = 'None'
}

const MenuUserInformation: React.FC<Props> = ({ iconType, onClick }) => {
  const {
    state: { user }
  } = useAppState()

  const showUserInformation = iconType !== UserInformationType.NONE

  return (
    <>
      <UserInformation
        fullPadding
        fullWidth
        direction="row"
        spacing={Spacings.tiny}
        gap={Spacings.min}
        align="center"
        justify="space-between"
      >
        <StyledBox fullWidth direction="row" gap={Spacings.min} align="center">
          {!showUserInformation && <UserPhoto imageKey={Images.userPhoto} />}
          {showUserInformation && <ExperimentalImageContainer imageKey={Images.userPhoto} />}
          <CredentialsWrapper fullWidth align="flex-start" justify="center">
            <TextContainer
              textKey={Texts.UserName}
              text={user?.name}
              style={{ fontSize: '14px', lineHeight: 'auto' }}
            />
            <Email
              textKey={Texts.UserEmail}
              text={user?.email}
              style={{ fontSize: '12px', lineHeight: 'auto' }}
              showUserInformation={showUserInformation}
            />
          </CredentialsWrapper>
          {showUserInformation && (
            <StyledBox onClick={onClick}>
              <StyledSVGContainer
                SVG={iconType === UserInformationType.LOG_OUT ? userMenuLogoutIcon : navigationUserMenuIcon}
              />
            </StyledBox>
          )}
        </StyledBox>
      </UserInformation>
    </>
  )
}

export default MenuUserInformation

const UserInformation = styled(StyledBox)`
  border-bottom: ${SIDEBAR_ELEMENTS_BORDER};
  border-top: ${SIDEBAR_ELEMENTS_BORDER};
`
const UserPhoto = styled(ExperimentalImageContainer)`
  img {
    min-width: ${SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE} !important;
    max-height: ${SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE} !important;
    max-width: ${SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE} !important;
  }
`
const StyledSVGContainer = styled(SVGContainer)`
  cursor: pointer;
`
const CredentialsWrapper = styled(StyledBox)`
  overflow: hidden;
`
const Email = styled(TextContainer)<{ showUserInformation: boolean }>`
  span {
    display: inline-block;
    width: ${({ showUserInformation }) => `calc(100% - ${showUserInformation ? Spacings.smallest : Spacings.large})`};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`

import BackOfficeLayout from 'src/components/BackOfficeLayout'
import Overview from 'src/components/Overview'
import { Images } from 'src/figma/helpers/imageHelper'
import { Texts } from 'src/figma/helpers/TextRepository'

const Home: React.FC = () => {
  return (
    <BackOfficeLayout
      showHeading
      pageHeading={Texts.OverviewHeading}
      pageSubheading={Texts.OverviewSubheading}
      backgroundImage={Images.overviewBackgroundMobile}
      fullBackgroundImage
    >
      <Overview />
    </BackOfficeLayout>
  )
}

export default Home

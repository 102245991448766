import React, { SVGProps } from 'react'
import styled from 'styled-components'

const SVGContainer: React.FC<{
  SVG: React.FC<SVGProps<SVGSVGElement>>
  svgColor?: string
  svgStyles?: { [name: string]: string }
  className?: string
}> = ({ SVG, svgColor, svgStyles, className }) => {
  return <Wrapper className={className}>{!!SVG && <SVG color={svgColor} style={{ ...svgStyles }} />}</Wrapper>
}
const Wrapper = styled.span``
export default SVGContainer

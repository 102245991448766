export default {
  linearBlue: 'linear-gradient(180deg, #7dd6c0 0%, #059ba5 28.44%, #086a8d 65.42%, rgba(0, 29, 73, 0.9) 100%)',
  secondaryButtonColor: '#C9E0E9',
  primaryButtonHoverColor: '#69A6BD',
  tertiaryButtonBackgroundColor: '#F1CCCE',
  tertiaryButtonBorderColor: '#E30733',
  hoverGray: '#FAFAFA',
  lightStone: '#E9E9E9',
  loginButtonColor: '#004672',
  autoLoginButtonColor: '#65a4cb',
  bhBlueMedium: '#17789C'
}

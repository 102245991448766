import React, { useMemo } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import { generateUUID } from 'src/helpers/Common'
import { getOverviewNotificationBoxes, getOverviewRedirectionBoxes } from 'src/helpers/OverviewHelper'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import styled from 'styled-components'
import { Line } from './Line'
import OverviewNotificationBox from './OverviewNotificationBox'
import OverviewRedirectionBox from './OverviewRedirectionBox'

const Overview: React.FC = () => {
  const isMobile = useIsMobile()
  const redirectionBoxes = useMemo(() => getOverviewRedirectionBoxes(), [])
  const notificationBoxes = useMemo(() => getOverviewNotificationBoxes(), [])

  return (
    <StyledBox gap={Spacings.big} bottom={Spacings.big} align="center">
      {isMobile && (
        <StyledBox fullWidth left right spacing={Spacings.large} gap={Spacings.min}>
          <TextContainer textKey={Texts.OverviewHeading} />
          <TextContainer textKey={Texts.OverviewSubheading} />
          <Line fullWidth />
        </StyledBox>
      )}
      <Wrapper direction="row" left right spacing={Spacings.large} gap={Spacings.big} justify="center">
        {!!notificationBoxes &&
          notificationBoxes.map(({ icon, title, notificationMessage, emptyStateTextKey }) => {
            return (
              <OverviewNotificationBox
                key={generateUUID()}
                icon={icon}
                title={title}
                notificationMessage={notificationMessage}
                emptyStateTextKey={emptyStateTextKey}
              />
            )
          })}
      </Wrapper>
      <Line fullWidth />
      <Wrapper direction="row" left right spacing={Spacings.large} gap={Spacings.big} justify="center">
        {!!redirectionBoxes &&
          redirectionBoxes.map(({ image, title, description, url }) => {
            return (
              <OverviewRedirectionBox
                key={generateUUID()}
                image={image}
                title={title}
                description={description}
                url={url}
              />
            )
          })}
      </Wrapper>
    </StyledBox>
  )
}

export default Overview

const Wrapper = styled(StyledBox)`
  flex-wrap: wrap;
`

import React from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import { SIDEBAR_BUTTON_HEIGHT, SIDEBAR_DIVIDER_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import { Line } from './Line'

type DividerProps = {
  textKey: TextType
}

const SidebarDivider: React.FC<DividerProps> = ({ textKey }) => {
  return (
    <DividerWrapper fullWidth justify="flex-end" gap={Spacings.smallest} bottom={Spacings.minimum}>
      <Line fullWidth />
      <GeneralTextWrapper fullWidth left={Spacings.tiny} justify="center">
        <TextContainer textKey={textKey} />
      </GeneralTextWrapper>
    </DividerWrapper>
  )
}

export default SidebarDivider

const DividerWrapper = styled(StyledBox)`
  height: ${SIDEBAR_DIVIDER_HEIGHT};
`

const GeneralTextWrapper = styled(StyledBox)`
  height: ${SIDEBAR_BUTTON_HEIGHT};
`
